

import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';

@Component({
    components: {
        ModalWrapper,
    },
})
export default class WarningPopup extends Vue {
    @Prop({
        type: String,
        default: 'Warning',
    })
    title!: string;

    @Prop({
        type: String,
        default: 'Close',
    })
    closeBtnText!: string;

    @Prop({
        required: false,
        type: Function,
    })
    private onClose?: () => void;

    @Prop({
        required: false,
        type: Function,
    })
    private onClickButton?: () => void;

    handleClose() {
        if (this.onClickButton) {
            this.onClickButton();
            return;
        }
        this.$router.push({
            name: this.$route.matched[this.$route.matched.length - 2].name,
            params: this.$route.params,
            query: this.$route.query,
        });
    }
}
