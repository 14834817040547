
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CompsetModel from '@/modules/compsets/models/compset.model';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';

@Component
export default class CompsetTitle extends Vue {
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;

    @Prop({ type: Object, default: null })
    compset!: CompsetModel | null;

    @Prop({ type: Boolean, default: false })
    isActive!: boolean;

    name: string | null = this.compset ? this.compset.name : null;
    isEdititng: boolean = false;

    updated() {
        if (!this.isActive && this.isEdititng) {
            this.isEdititng = false;
        }

        if (!this.isActive) {
            this.name = this.compset?.name || null;
        }
    }

    get numberOfCompetitors() {
        if (!this.compset) {
            return null;
        }

        return this.compset.competitors.length;
    }

    handleEdit() {
        this.isEdititng = true;
        const input = this.$refs.nameInput as HTMLInputElement;
        // make it focus on next tick, coz on current it is disabled
        this.$nextTick(() => input.focus());
    }

    handleChange(e: Event) {
        e.stopPropagation();

        if (this.compset && this.name) {
            this.isEdititng = false;
            this.compsetsService.setUpdatedCompsetName(this.compset, this.name);
        }
    }

    handleClear(e: Event) {
        e.stopPropagation();

        if (this.compset) {
            this.isEdititng = false;
            this.name = this.compset.name;
        }
    }

    handleClick() {
        if (this.compset && !this.isEdititng) {
            this.$emit('compsetSelect', this.compset);
        }
    }
}
